function IsNewDisplay(IsNew){
  return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
}
module.exports = {
  IsNewDisplay:(IsNew) => {
    return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
  },
  IsCertifiedDisplay:(IsCertified) => {
    return (IsCertified === true ? "Certified " : "" )
  },

  configurableCompareString: (theString, compareVehicleInfo) => {
    let finalString = theString
    finalString = finalString.split("{{compareModel}}").join(compareVehicleInfo.compareModel)
    finalString = finalString.split("{{mainModel}}").join(compareVehicleInfo.mainModel)
    return finalString
  },
  configurableString: (theString, VehicleInfo, phoneNumber) => {
    let finalString = theString
    finalString = finalString.split("{{new}}").join(IsNewDisplay(VehicleInfo.IsNew))
    finalString = finalString.split("{{vin}}").join(VehicleInfo.VIN)
    finalString = finalString.split("{{stock}}").join(VehicleInfo.StockNumber)
    finalString = finalString.split("{{year}}").join(VehicleInfo.Year)
    finalString = finalString.split("{{make}}").join(VehicleInfo.Make)
    finalString = finalString.split("{{model}}").join(VehicleInfo.Model)
    finalString = finalString.split("{{bodytype}}").join(VehicleInfo.BodyType)
    finalString = finalString.split("{{phoneNumber}}").join(phoneNumber?phoneNumber:"")
    return finalString
  },
  /*
  * remove all spaces " " and replace with dash "-"
  *
  * */
  configurableLink: (theLink, VehicleInfo) => {
    let finalLink = theLink
    finalLink = finalLink.split("{{new}}").join(IsNewDisplay(VehicleInfo.IsNew))
    finalLink = finalLink.split("{{vin}}").join(VehicleInfo.VIN)
    finalLink = finalLink.split("{{stock}}").join(VehicleInfo.StockNumber)
    finalLink = finalLink.split("{{year}}").join(VehicleInfo.Year)
    finalLink = finalLink.split("{{make}}").join(VehicleInfo.Make.split(" ").join("-"))
    finalLink = finalLink.split("{{model}}").join(VehicleInfo.Model.split(" ").join("-"))
    finalLink = finalLink.split("{{bodytype}}").join(VehicleInfo.BodyType.split(" ").join("-"))
    finalLink = finalLink.split("/-/").join("/")
    return finalLink
  },
  /*
  * Slug add / to the beginning of the final return
  *
  * */
  configurableSlug: (theSlug, VehicleInfo) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.split("{{new}}").join(IsNewDisplay(VehicleInfo.IsNew))
    finalSlug = finalSlug.split("{{vin}}").join(VehicleInfo.VIN)
    finalSlug = finalSlug.split("{{stock}}").join(VehicleInfo.StockNumber)
    finalSlug = finalSlug.split("{{year}}").join(VehicleInfo.Year)
    finalSlug = finalSlug.split("{{make}}").join(VehicleInfo.Make.split(" ").join("-"))
    finalSlug = finalSlug.split("{{model}}").join(VehicleInfo.Model.split(" ").join("-"))
    finalSlug = finalSlug.split("{{bodytype}}").join(VehicleInfo.BodyType.split(" ").join("-"))
    finalSlug = finalSlug.split("/-/").join("/")
    return "/" + (finalSlug == "" ? VehicleInfo.VIN : finalSlug)
  },
  /*
  * If you don't have the VehicleInfo data you can pass them in a indiviual items
  * */
  configurableSlugManual: (theSlug, VIN, Year, Make, Model, BodyType,IsNew) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.split("{{new}}").join(IsNewDisplay(IsNew))
    finalSlug = finalSlug.split("{{vin}}").join(VIN)
    finalSlug = finalSlug.split("{{year}}").join(Year)
    finalSlug = finalSlug.split("{{make}}").join(Make.split(" ").join("-"))
    finalSlug = finalSlug.split("{{model}}").join(Model.split(" ").join("-"))
    finalSlug = finalSlug.split("{{bodytype}}").join(BodyType.split(" ").join("-"))
    finalSlug = finalSlug.split("/-/").join("/")
    return "/" + (finalSlug == "" ? VIN : finalSlug)
  }
}